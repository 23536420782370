import swiper from "swiper";

const sepecialContent = new swiper(".js-recruit-slider", {
  // Optional parameters
  direction: "horizontal",
  loop: true,
  autoplay: {
    delay: 0
  },
  effect: "fade",
  slidesPerView: 1,
  speed: 7000,
  spaceBetween: 40,
  centeredSlides: true,
  observer: true,
  observeParents: true,
  preloadImages: true,
  // noSwiping: true,
  autoHeight: true,
  lazy: {
    loadPrevNext: true,
    loadPrevNextAmount: 2,
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  // navigation: {
  //   nextEl: ".special-content-swiper-button-next",
  //   prevEl: ".special-content-swiper-button-prev",
  // },
  breakpoints: {
   
  },
});
