const modal = (() => {
  class Modal {
    constructor() {
      this.body = document.querySelector('body');
      this.targets = document.querySelectorAll('.js-modal');
      this.modalContents = document.querySelectorAll('[data-modal]');
      this.scrollPosition = "";
      this.openFlag = "";
      this.modalOpenTarget = "";
    }

    init() {
      for (let target of this.targets) {
        target.addEventListener('click', (e) => {
          this.modalOpenTarget = e.target.closest('.js-modal').getAttribute('data-modal-target');
          this.setFlag(this.modalOpenTarget);
          this.show();
        })
      }
      // this.clickOutside();

    }

    setScrollPosition() {
      this.scrollPosition = window.scrollY
    }

    setFlag(openTarget) {
      // this.openFlag = true
      if (this.body.classList.contains('js-modal-close')) {
        this.body.classList.toggle('js-modal-close')
        document.querySelector(`[data-modal="${openTarget}"]`).classList.toggle('js-modal-target-close');
      }
      if (this.body.classList.contains('js-modal-open')) {
        this.body.classList.toggle('js-modal-close')
        document.querySelector(`[data-modal="${openTarget}"]`).classList.toggle('js-modal-target-close');
      }
      this.body.classList.toggle('js-modal-open')
      document.querySelector(`[data-modal="${openTarget}"]`).classList.toggle('js-modal-target-open');
      this.openFlag = this.body.classList.contains('js-modal-open') ? true : false
    }

    show() {
      if (this.openFlag) {
        this.scrollPosition = window.scrollY
        const openStyle = new Map([
          ["position", "fixed"],
          ["top", -1 * this.scrollPosition + "px"],
          ["height", "100%"]
        ])
        // $("#js-modal").fadeIn(250);
        for (const [key, value] of openStyle) {
          this.body.style[key] = value;
        }
      } else {
        const closeStyle = new Map([
          ["position", "static"],
          ["overflow", "visible"],
          ["height", "auto"]
        ]);
        // $("#js-modal").fadeOut(250);
        for (const [key, value] of closeStyle) {
          this.body.style[key] = value;
        }
        window.scrollTo(0, this.scrollPosition);
      }
    }

    clickOutside() {
      for (let modalContent of this.modalContents) {
        modalContent.addEventListener('click', (e) => {
          if (this.modalOpenTarget = e.target.getAttribute('data-modal')) {
            this.setFlag(this.modalOpenTarget);
            this.show();
            console.log(e.target.getAttribute('data-modal'))
          }
        })
      }
    }
  }
  return new Modal();
})();

export default modal;




//jQuery
;(function($) {
	jQuery.fn.modal = function(config) {
		var options = $.extend({
			showContentId: 'modalContents',//modalのID
			centerMode: false,//ウインドウの縦横中央に表示
			youtubeWidthLimit: 800,//youtubeの幅の上限
			width: '70%',//modalの幅 youtubeを表示する場合は無効
			height: 'auto'//modalの高さ youtubeを表示する場合は無効
		}, config);
		$(this).on("click", function(){

			//現在のスクロール位置の取得
			var currentPosition = $(document).scrollTop();

			// console.log(contetHeight);

			//モーダルウィンドウ生成
			$("body").append('<div id="'+options.showContentId+'"></div>');
			$('#'+options.showContentId).css({
				width: options.width,
				height: options.height
			});

			var title = $(this).data('title');
			if(title) {
				// console.log(title);
				$('#'+options.showContentId).append('<div id="modalTitle"></div>');
				$('#'+options.showContentId).find("#modalTitle")
					.empty()
					.prepend($('<p>'+title+'</p>'));
			}

			var text = $(this).data('text');
			if(text) {
				// console.log(text);
				$('#'+options.showContentId).append('<div id="modalText"></div>');
				$('#'+options.showContentId).find("#modalText")
					.empty()
					.prepend($('<p>'+text+'</p>'));
			}

			//htmlファイルを読み込み
			var html = $(this).data('html');
			if(html) {
				// console.log(html);
				$('#'+options.showContentId).append('<div id="modalLoad-html"></div>');
				$('#'+options.showContentId).find("#modalLoad-html")
					.empty()
					.load(html);
			}

			//data属性にyoutubeIDがある場合youtube動画生成
			var youtubeId = $(this).data('youtube');
			if(youtubeId) {
				//動画の幅を設定
				var widthBase = $(window).width() - ($(window).width() / 10);
				var movieContetWidth = widthBase >= options.youtubeWidthLimit ? options.youtubeWidthLimit : widthBase;
				var movieContetHeight = movieContetWidth * 9 / 16;
				$('#'+options.showContentId).css({
					width: movieContetWidth,
					height: movieContetHeight
				});
				$('#'+options.showContentId).addClass('modalMovieWrap')
				$('#'+options.showContentId).append('<div id="modalMovie"></div>');
				$('#'+options.showContentId).find("#modalMovie")
					.empty()
					.prepend($('<iframe width="'+movieContetWidth+'" height="'+movieContetHeight+'" src="//www.youtube.com/embed/' + youtubeId + '?rel=0&autoplay=1' + '" frameborder="0" allowfullscreen></iframe>'));
			}

			//オーバーレイを出現させる
			$("body").append('<div id="search_modal_overlay"></div>');
			$("#search_modal_overlay").fadeIn("slow");

			//コンテンツをフェードインする
			$('#'+options.showContentId).fadeIn("slow");

			//閉じるボタン生成
			$('#'+options.showContentId).prepend('<div class="modalRemove">閉じる</div>');

			//コンテンツの表示位置
			if(options.centerMode) {
				$('#'+options.showContentId).offset({ top : currentPosition + ($(window).height() / 2) - ($("#modalContents").height() /2 )});
			} else {
				$('#'+options.showContentId).offset({ top : currentPosition + 50});
			}

			//[#search_modal_overlay]、または[#modalRemove]をクリックしたら…
			$("#search_modal_overlay,.modalRemove").on('click', function(){
				//[#search_modal]と[#search_modal_overlay]をフェードアウトした後に…
				$('#'+options.showContentId).add("#search_modal_overlay").fadeOut("slow",function(){
					//モーダルウィンドウを削除する
					$('#search_modal_overlay').add('#'+options.showContentId).remove();
					//youtube削除
					// $("#modalContents").find("#modalMovie")
					// .empty();
				});
			});
		});
	};
})(jQuery);
jQuery(function($){
  //モーダルウインドウ
  $('.modalOpen-js').modal({
    centerMode: true
    // width: '88%'
  });
});