import swiper from "swiper";

var serviseNav = new swiper(".js-recruit-welfare-slider", {
  direction: "horizontal",
  loop: true,
  speed: 500,
  autoplay: false,
  spaceBetween: 20,
  centeredSlides: true,
  observer: true,
  allowTouchMove: true,
  preloadImages: false,
  autoHeight: true,
  slidesPerView: 1.5,
  lazy: {
    loadPrevNext: true,
    loadPrevNextAmount: 2
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true
  },
  breakpoints: {
    // 960: {
    //   slidesPerView: 2
    // },
    414: {
      slidesPerView: 1.2
    }
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  }
});
