const recruitButton = Array.from(
  document.querySelectorAll(".content-detail__inner")
);

if (recruitButton) {
  const underLine = Array.from(document.querySelectorAll(".under-line"));
  const icon = Array.from(document.querySelectorAll(".icon"));

for (let i = 0; i < recruitButton.length; i++) {
  recruitButton[i].addEventListener("mouseenter", () => {
    underLine[i].classList.add("--active");
    icon[i].classList.add('--active');
  });
  recruitButton[i].addEventListener("mouseleave", () => {
    underLine[i].classList.remove("--active");
    icon[i].classList.remove('--active');
  });
}
}
