import mixitup from 'mixitup';

if($('.recruit-gallery-photos').length) {

    var mixer = mixitup('.recruit-gallery-photos', {
      load: {
          filter: 'all'
      }
    });
} 
