
class VideoPlayer {
  constructor() {
    this.targets = document.querySelectorAll('.js-video-player')
  }

  init() {
    this.fixed();
    this.attachEvent();
  }

  fixed() {
    for (const target of this.targets) {
      let video = target.querySelector('.js-video-player-content')
      let width = target.clientWidth
      let height = target.clientHeight
      let ratio = target.getAttribute('data-ratio').split(':')
      let zoom = target.getAttribute('data-zoom')
      let flag = width * ratio[1] / ratio[0] < height
      let useSize = flag ? height : width
      let useRatio = flag ? ratio[0] / ratio[1] : ratio[1] / ratio[0]
      let videoWidth = video.clientWidth
      let videoHeight = video.clientHeight
      let style


      // console.log(width * ratio[1] / ratio[0])


      if (zoom == 'true') {
        style = new Map([
          ['position', 'absolute'],
          ['width', flag ? `${useSize * useRatio}px` : `${useSize}px`],
          ['height', flag ? `${useSize}px` : `${useSize * useRatio}px`],
          ['left', flag ? `${-(videoWidth - width) / 2}px` : 0],
          ['top', flag ? 0 : `${-(videoHeight - height) / 2}px`]
        ])
      } else {
        style = new Map([
          ['position', 'absolute'],
          ['width', flag ? `100%` : `${height * ratio[0] / ratio[1]}px`],
          ['height', flag ? `${width * ratio[1] / ratio[0]}px` : `${height}px`],
          ['left', flag ? `0` : `${-(videoWidth - width) / 2}px`],
          ['top', flag ? 0 : `${-(videoHeight - height) / 2}px`]
        ])
        if(flag) {
          $(".work-video__inner").addClass('is-loaded');

        } else {

        }

      }

      for (const [key, value] of style) {
        video.style[key] = value;
      }

      // this.hideCover(video);
      this.videoLoaded(video, target);
      this.videoEnded(video, target);
      this.videoStart(video, target);

    }
  }

  hideCover(video) {
    video.addEventListener('play', () => {
      $(".top-hero-videoCover").hide();
    });
  }

  videoEnded(video) {
    video.addEventListener('ended', () => {
      $("body").addClass("is-video-ended");
    });
  }

  videoStart(video) {
    $('.js-video-player-start').on('click', function() {
      video.play();
      $(this).hide();
    })
  }

  videoLoaded(video) {
    video.addEventListener('play', () => {

      this.fixed();
    });
    video.addEventListener('canplaythrough', () => {

      this.fixed();
    });
  }

  attachEvent() {
    window.addEventListener('resize', () => {
      this.fixed();
      $(".top-bgMovie").addClass('is-loaded');
    })
    window.addEventListener('load', () => {
      this.fixed();
      $(".work-video__inner").addClass('is-loaded');
    })
  }

}

const videoPlayer = new VideoPlayer();
videoPlayer.init();

$(window).on('load', function () {
  $(".top-bgMovie").addClass('is-loaded');
})
