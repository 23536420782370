// let hogehoge = document.querySelector('.top-work-video-modal__content-main');

// $('.top-work-video').on('click', function() {
  
//   console.log("ddd")
//   hogehoge.play();
//   // $(this).hide();
// })
// $('.top-work-video-modal').on('click', function() {
  
//   // console.log("ddd")
//   hogehoge.pause();
//   // $(this).hide();
// })
// $('.top-work-video-modal__content-close').on('click', function() {
  
//   // console.log("ddd")
//   hogehoge.pause();
//   // $(this).hide();
// })


// document.addEventListener('click', (e) => {
//   if(!e.target.closest('.top-work-video-modal')) {
//     hogehoge.pause();
//   } else {
//     //ここに内側をクリックしたときの処理
//   }
// })
// $('.js-modal-target-open').on('click', function() {
  
//   console.log("ddd")
//   hogehoge.play();
//   // $(this).hide();
// })

